.mobile_menu_bar_toggle {
  &:before {
    color:white;
  }
}
.et_mobile_menu {
  border-top:3px solid #496467;
}
#et-secondary-menu {
  float:left;
  width:100%;
}

#et-secondary-nav {
  li {
    a {
      pointer-events: none !important;
      &:before {
        content: "\4e";
        font-family:"ETModules";
        color:rgb(0, 204, 0);
        font-weight: bold;
        position:relative;
        top:1px;
        left:-1px;
      }
    }
  }
}
.et-cart-info {
  float:right;
  span, span:before {
    color:#496467;
  }
}

@media screen and (max-width:1500px) and (min-width:1400px) {
  #logo {
    max-height:51%;
  }
}

@media screen and (max-width:1399px) and (min-width:1395px) {
  #logo {
    max-height:51%;
    max-width:340px;
  }
}
@media screen and (max-width:1394px) and (min-width:981px) {
  /*#logo, #logo:after {
    content:url("/wp-content/themes/Divi-child/web/img/dierenverjagers-logo-small.png");
  }*/
  #logo {
    display: inline-block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: url("/wp-content/themes/Divi-child/web/img/dierenverjagers-logo-small.png") no-repeat;
    width:92px;
    height:89px;
    padding-left:92px;
    max-height:100%;
  }
}

#top-menu li li.current-menu-item>a {
  color:#8ca4a6 !important;
}