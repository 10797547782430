@media screen and (max-width:980px) and (min-width:480px) {
  .wooloop {
    .et_woo_container_column {
      width: 47.25%!important;
    }
  }
}

.voordelen li {
  float:left;
  margin-right:10px;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  text-transform: none;
  text-decoration: none;
  color: #8ca4a6;

  &:before {
    content: "\4e";
    font-family:"ETModules";
    color:rgb(0, 204, 0);
    font-weight: bold;
    position:relative;
    top:1px;
    left:-1px;
  }
}

.single-product.woocommerce  {
  .up-sells {
    h2 {
      font-size:18px !important;
    }
    .products li {
      .woocommerce-loop-product__title, .price {
        display:none !important;
      }
      &:first-of-type {
        margin-left:0px !important;
      }
      .add_to_cart_button {
        display:none !important;
      }
    }
  }
  div.product .woocommerce-tabs ul.tabs li.active a {
    color:white !important
  }

  .related.products ul.products li.product:nth-child(3n+1) {
    clear:none !important;
  }
  #nav_menu-3 {
    width:100%;
  }
  #menu-betaalmethoden {
    li {
      float:left;
      margin-right:10px;
      #Sofort {
        margin-left:0px;
      }
    }
  }
  div.product div.images .flex-control-thumbs li {
    padding: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: #8ca4a6;
    -webkit-transition: opacity .3s ease-out;
    transition: opacity .3s ease-out;
    -webkit-transform: translateZ(0);
  }
}

.woocommerce.single-product {
  .related ul li {
    width:22% !important;
    margin-right:2% !important;
    &:nth-of-type(4) {
      margin-right:0px !important;
    }
    a {
      width:100%;
      float:left;
      .price {
        min-height:50px;
        margin-bottom:10px;
      }
    }
    .add_to_cart_button {
      margin-top:10px !important;
    }
  }
}

.isb_avada_green, .isb_orange {
  fill: #f7782d;
  background-color: #f7782d;
  outline-color: #f7782d !important;
}

.et_overlay {
  &:before {
    color:#8ca4a6;
  }
}
