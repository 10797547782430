@function rem($px) {
  @return $px/$em * 1rem;
}

// use this for breakpoints only, otherwise use rem()
@function em($px) {
  @return $px / 16 * 1em;
}

@function image-url($filename, $image-url:$image-url) {
  @return url($image-url + $filename);
}

@function font-url($filename, $font-url:$font-url) {
  @return url($font-url + $filename);
}