.et_pb_button {
  &.fullwidth {
    width: 100%;
    padding-top:20px !important;
    padding-bottom:20px !important;
    &:hover {
      padding-top:20px !important;
      padding-bottom:20px !important;
    }
  }
}