//////////////////////////////
// Default Variables
//////////////////////////////
// Default Features
$breakpoint-default-media:      all !default;
$breakpoint-default-feature:    min-width !default;
$breakpoint-default-pair:       width !default;

// Default Transforms
$breakpoint-force-media-all:    false !default;
$breakpoint-to-ems:             false !default;
$breakpoint-resolutions:        true !default;

// Default No Query Options
$breakpoint-no-queries:         false !default;
$breakpoint-no-query-fallbacks: false !default;

// Deftault Base Font Size
$breakpoint-base-font-size:     16px !default;

// Legacy Syntax Support
$breakpoint-legacy-syntax:      false !default;

//////////////////////////////
// Imports
//////////////////////////////
@import 'breakpoint/context';
@import 'breakpoint/helpers';
@import 'breakpoint/parsers';
@import 'breakpoint/no-query';

@import 'breakpoint/respond-to';

//////////////////////////////
// Breakpoint Mixin
//////////////////////////////

@mixin breakpoint($query, $no-query: false) {
  // Reset contexts
  @include private-breakpoint-reset-contexts();

  $breakpoint: breakpoint($query, false);

  $query-string: map-get($breakpoint, 'query');
  $query-fallback: map-get($breakpoint, 'fallback');

  $private-breakpoint-context-holder: map-get($breakpoint, 'context holder') !global;
  $private-breakpoint-query-count: map-get($breakpoint, 'query count') !global;

  // Allow for an as-needed override or usage of no query fallback.
  @if $no-query != false {
    $query-fallback: $no-query;
  }

  @if $query-fallback != false {
    $context-setter: private-breakpoint-set-context('no-query', $query-fallback);
  }

  // Print Out Query String
  @if not $breakpoint-no-queries {
    @media #{$query-string} {
      @content;
    }
  }

  @if $breakpoint-no-query-fallbacks != false or $breakpoint-no-queries == true {

    $type: type-of($breakpoint-no-query-fallbacks);
    $print: false;

    @if ($type == 'bool') {
      $print: true;
    }
    @else if ($type == 'string') {
      @if $query-fallback == $breakpoint-no-query-fallbacks {
        $print: true;
      }
    }
    @else if ($type == 'list') {
      @each $wrapper in $breakpoint-no-query-fallbacks {
        @if $query-fallback == $wrapper {
          $print: true;
        }
      }
    }

    // Write Fallback
    @if ($query-fallback != false) and ($print == true) {
      $type-fallback: type-of($query-fallback);

      @if ($type-fallback != 'bool') {
        #{$query-fallback} & {
          @content;
        }
      }
      @else {
        @content;
      }
    }
  }

  @include private-breakpoint-reset-contexts();
}


@mixin mq($query, $no-query: false) {
  @include breakpoint($query, $no-query) {
    @content;
  }
}
